import WasmController from "react-lib/frameworks/WasmController";

// Interface
import { State as MainState } from "../../../../../HIS/MainHISInterface";

// Utils
import moment from "moment";
import { dateToStringWithoutTimeBE, dateToStringAD } from "react-lib/utils/dateUtils";

// Common
import { downloadXLSX } from "react-lib/apps/HISV3/common/CommonInterface";

// APIs
import ExportIncomeDataForImportSAP from "issara-sdk/apis/ExportIncomeDataForImportSAP_apps_INF";
import MedicalExpenseIncomeByCostCenterReport from "issara-sdk/apis/MedicalExpenseIncomeByCostCenterReport_apps_INF";
import PaymentStatisticReport from "issara-sdk/apis/PaymentStatisticReport_apps_INF";
import CostCenterList from "issara-sdk/apis/CostCenterList_core";

export type State = Partial<{
  // Sequence
  SapAccountingReportSequence: Partial<{
    sequenceIndex: "Start" | "Action" | null;
    selectedMenu: string;
    costCenterOption: any;
    // FI Export
    sapFIExport: Partial<{
      sendMoneyDate: string;
      summaryDate: string;
      selectedStation: string;
    }>;
    // รายงานสรุปรายได้ตามศูนย์ต้นทุน
    sapMedExpenseCostCenter: Partial<{
      reportType: string;
      selectedAccountType: string;
      selectedStation: string;
      selectedCoverage: string;
      selectedCostCenter: string;
      selectedFiscalYear: string;
      sendMoneyDate: string;
      selectedMonth: string;
    }>;
    // รายงานสรุปสถิติการรับชำระเงิน
    sapPaymentStatReport: Partial<{
      reportType: string;
      selectedStation: string;
      selectedFiscalYear: string;
      selectedMonth: string;
      sendMoneyDate: string;
    }>;
  }>;
}>;

type Picked = Partial<Pick<MainState, "buttonLoadCheck" | "errorMessage" | "django">>;

export const StateInitial: State = {
  // Sequence
  SapAccountingReportSequence: {
    sequenceIndex: null,
  },
};

export type Event =
  | { message: "RunSequence"; params: {} }
  | { message: "GetMasterData"; params: {} };

export type Data = {
  division?: number;
  device?: number;
};

export const DataInitial = {};

// Const
const Masters = [
  ["coverage", {}],
  ["bilStation", {}],
  // ["bilStationLog", {}],
  // ["payer", {}],
  // ["division", {}],
];

const BTN_ACTION = `SapAccountingReport_EXPORT_REPORT`;

const CURRENT_YEAR = Number(moment().format("YYYY")) + 543; // 2567
const CURRENT_MONTH = Number(moment().locale("th").format("MM")); // 1 (มกราคม)

const nowDateBeForFilter = dateToStringWithoutTimeBE(moment()); // 23/04/2567

type Handler = (controller: WasmController<State & Picked, Event, Data>, params?: any) => any;

/* ------------------------------------------------------ */
/*                          START                         */
/* ------------------------------------------------------ */
export const Start: Handler = async (controller, params) => {
  console.log("SapAccument START State");

  let state = controller.getState();

  controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: Masters,
    },
  });

  console.log("SapAcc Controller Data: ", controller);
  console.log("SapAcc Params Data: ", params);
  console.log("SapAcc Const: ", CURRENT_YEAR + " " + CURRENT_MONTH);

  const [costCenterRes, costCenterErr, costCenterNet] = await CostCenterList.list({
    extra: {
      device: controller.data.device,
      division: controller.data.division,
    },
    apiToken: controller.apiToken,
  });

  let costCenterData = [];
  if (costCenterRes) {
    costCenterData = (costCenterRes?.items || []).map((item: any) => {
      return { key: item.code, value: item.code, text: `${item.name} (${item.code})` };
    });
  }

  controller.setState(
    {
      SapAccountingReportSequence: {
        ...state.SapAccountingReportSequence,
        costCenterOption: costCenterData,
        sequenceIndex: "Action",
        // Initial Sub-menu
        sapFIExport: {
          // sendMoneyDate: nowDateBeForFilter,
          summaryDate: nowDateBeForFilter,
        },
        sapMedExpenseCostCenter: {
          sendMoneyDate: nowDateBeForFilter,
          reportType: "daily",
        },
        sapPaymentStatReport: {
          sendMoneyDate: nowDateBeForFilter,
          reportType: "daily",
          selectedFiscalYear: CURRENT_YEAR.toString(),
          selectedMonth: `${CURRENT_MONTH} ${CURRENT_YEAR}`,
        },
      },
    },
    () => Action(controller, { action: "FETCH_REPORT" })
  );
};

/* ------------------------------------------------------ */
/*                         ACTION                         */
/* ------------------------------------------------------ */
export const Action: Handler = async (controller, params) => {
  if (params.action === "FETCH_DATA") {
    HandleFetchData(controller, params);
  } else if (params.action === "GET_MENU") {
    HandleGetMenu(controller, params);
  } else if (params.action === "EXPORT_REPORT") {
    HandleExportReport(controller, params);
  }
};

const HandleFetchData: Handler = async (controller, params) => {
  console.log("SapAcc Seq FETCH_REPORT SEQUENCE");

  let state = controller.getState();

  console.log("SapAccl FETCH_REPORT Data: ", state.SapAccountingReportSequence);
};

const HandleGetMenu: Handler = async (controller, params) => {
  let state = controller.getState();

  if (!params.menu) {
    Action(controller, { action: "FETCH_REPORT" });
  }

  switch (params.menu) {
    case "SAP FI Export":
    case "รายงานสรุปรายได้ตามศูนย์ต้นทุน":
    case "รายงานสรุปสถิติการรับชำระเงิน":
      state = controller.getState();

      controller.setState({
        SapAccountingReportSequence: {
          ...state.SapAccountingReportSequence,
          selectedMenu: params.menu,
          // Other Params
        },
      });
      break;
    default:
      console.warn("SapAcc Your Menu: ", params.menu);
  }
};

const HandleExportReport: Handler = async (controller, params) => {
  console.log("SapAcc Seq Preview");

  let state = controller.getState();

  console.log("SapAcc Props Now: ", state.SapAccountingReportSequence);

  switch (params.menu) {
    case "SAP FI Export":
      state = controller.getState();

      controller.setState({
        buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTION]: "LOADING" },
      });

      const [sapFIResult, sapFIError] = await ExportIncomeDataForImportSAP.get({
        apiToken: controller.apiToken,
        params: {
          send_money_date: state.SapAccountingReportSequence?.sapFIExport?.sendMoneyDate,
          summary_date: state.SapAccountingReportSequence?.sapFIExport?.summaryDate,
          ...(state.SapAccountingReportSequence?.sapFIExport?.selectedStation !== "ทุก Station" && {
            station: state.SapAccountingReportSequence?.sapFIExport?.selectedStation,
          })
        },
        extra: { responseType: "arraybuffer" },
      });

      if (sapFIResult) {
        let exportDate = dateToStringAD(moment()); // Current Date (Export Date)
        let [datePart, timePart] = exportDate.split(" ");
        let dateFormatFile = datePart.replace(/\//g, "");
        let timeFormatFile = timePart.replace(/:/g, "");

        controller.setState({
          buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTION]: "SUCCESS" },
        });

        downloadXLSX(sapFIResult, `FI_${dateFormatFile}_${timeFormatFile}`);
      } else {
        controller.setState({
          buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTION]: "ERROR" },
        });

        console.warn("Cannot Export Excel File: ", sapFIError);
      }
      break;
    case "รายงานสรุปรายได้ตามศูนย์ต้นทุน":
      state = controller.getState();

      controller.setState({
        buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTION]: "LOADING" },
      });

      const [sapMedCostCenterResult, sapMedCostCenterError] =
        await MedicalExpenseIncomeByCostCenterReport.get({
          apiToken: controller.apiToken,
          params: {
            report_type: state.SapAccountingReportSequence?.sapMedExpenseCostCenter?.reportType,
            station:
              state.SapAccountingReportSequence?.sapMedExpenseCostCenter?.selectedStation === "ALL"
                ? ""
                : state.SapAccountingReportSequence?.sapMedExpenseCostCenter?.selectedStation,
            fiscal_year:
              state.SapAccountingReportSequence?.sapMedExpenseCostCenter?.selectedFiscalYear,
            month_and_year:
              state.SapAccountingReportSequence?.sapMedExpenseCostCenter?.selectedMonth,
            send_money_date:
              state.SapAccountingReportSequence?.sapMedExpenseCostCenter?.sendMoneyDate,
            account_type:
              state.SapAccountingReportSequence?.sapMedExpenseCostCenter?.selectedAccountType,
            coverage:
              state.SapAccountingReportSequence?.sapMedExpenseCostCenter?.selectedCoverage === "ALL"
                ? ""
                : state.SapAccountingReportSequence?.sapMedExpenseCostCenter?.selectedCoverage,
            cost_center:
              state.SapAccountingReportSequence?.sapMedExpenseCostCenter?.selectedCostCenter,
          },
          extra: { responseType: "arraybuffer" },
        });

      if (sapMedCostCenterResult) {
        controller.setState({
          buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTION]: "SUCCESS" },
        });

        downloadXLSX(sapMedCostCenterResult, "รายงานสรุปรายได้ตามศูนย์ต้นทุน");
      } else {
        controller.setState({
          buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTION]: "ERROR" },
        });

        console.warn("Cannot Export Excel File: ", sapMedCostCenterError);
      }
      break;
    case "รายงานสรุปสถิติการรับชำระเงิน":
      state = controller.getState();

      controller.setState({
        buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTION]: "LOADING" },
      });

      const [sapPaymentStatResult, sapPaymentStatError] = await PaymentStatisticReport.get({
        apiToken: controller.apiToken,
        params: {
          report_type: state.SapAccountingReportSequence?.sapPaymentStatReport?.reportType,
          station:
            state.SapAccountingReportSequence?.sapPaymentStatReport?.selectedStation === "ALL"
              ? ""
              : state.SapAccountingReportSequence?.sapPaymentStatReport?.selectedStation,
          fiscal_year: state.SapAccountingReportSequence?.sapPaymentStatReport?.selectedFiscalYear,
          month_and_year: state.SapAccountingReportSequence?.sapPaymentStatReport?.selectedMonth,
          send_money_date: state.SapAccountingReportSequence?.sapPaymentStatReport?.sendMoneyDate,
        },
        extra: { responseType: "arraybuffer" },
      });

      if (sapPaymentStatResult) {
        controller.setState({
          buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTION]: "SUCCESS" },
        });

        downloadXLSX(
          sapPaymentStatResult,
          "รายงานสถิติการรับชำระเงินด้วยวิธีการรับเงินประเภทต่างๆ"
        );
      } else {
        controller.setState({
          buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTION]: "ERROR" },
        });

        console.warn("Cannot Export Excel File: ", sapPaymentStatError);
      }
      break;
    default:
      console.warn("SapAcc has no Menu yet");
      break;
  }
};
